/*
 * CB Photo Gallery Mixins
 */

@mixin cb-photo-gallery-item($num, $gutter, $margin) {
  li {
    $width: calc((100% - (#{$margin} * #{$num})) / #{$num});

    width: $width;
  }
}
