/*
 * CB Anchor Menu
 */

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;

.cb-anchor-menu {
  position: fixed;
  z-index: 2;
  top: 96px;
  right: 24px;

  display: flex;
  flex-direction: column;

  @include media-breakpoint-only('xs', $grid-breakpoints) {
    top: 72px;
  }

  .cb-menu-list {
    position: absolute;
    top: 49px;
    right: -268px;

    overflow-y: auto;

    width: 240px;
    max-height: calc(100vh - 192px);

    background-color: $white;
    box-shadow: 0 0 24px rgb(0 0 0 / 20%);

    transition: right $transition-time-l ease;

    a {
      &.cb-menu-link {
        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }

  .cb-anchor-menu-btn {
    align-self: flex-end;

    &:focus {
      background-color: $white;
    }

    &.cb-btn-floating {
      border-radius: 8px;
    }

    .cb-menu-icon {
      position: relative;
      transform: rotate(0);

      display: block;

      width: 16px;
      height: 16px;

      transition: 0.5s ease-in-out;

      span {
        position: absolute;
        left: 0;

        width: 100%;
        height: 2px;

        opacity: 1;
        background: $black1;
        border-radius: 0;

        transition: $transition-time-s ease-in-out;

        &:nth-child(1) {
          top: 1px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 7px;
        }

        &:nth-child(4) {
          top: 13px;
        }
      }

      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }

  &.cb-open {
    .cb-anchor-menu-btn {
      span,
      a {
        span {
          &:nth-child(1),
          &:nth-child(4) {
            top: 18px;
            left: 50%;
            width: 0%;
          }

          &:nth-child(2) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .cb-menu-list {
      right: 0;
    }
  }

  &.cb-anchor-bottom {
    top: auto;
    bottom: 96px;

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      bottom: 72px;
    }

    .cb-menu-list {
      top: auto;
      bottom: 49px;
      max-height: calc(100vh - 240px);
    }
  }
}
