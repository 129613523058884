/*
 * CB Framework grid generation Mixins
 */

@use 'sass:math';
@use 'sass:map';

@use '../includes/apricot-variable' as *;
@use '../mixins/grid' as *;
@use '../mixins/breakpoint' as *;

@mixin grid-column-mixin($gutter) {
  position: relative;
  width: 100%;
  padding-right: math.div($gutter, 2);
  padding-left: math.div($gutter, 2);
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $grid-breakpoints: $grid-breakpoints) {
  @each $breakpoint in map.keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    /* Allow columns to stretch full width below their breakpoints */
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @include grid-column-mixin($gutter);
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @include grid-column-mixin($gutter);
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      /* Provide basic `.col-{bp}` classes for equal-width flexbox columns */
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;

        /* Reset earlier grid tiers */
        max-width: 100%;
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      /* `$columns - 1` because offsetting by the width of an entire row isn't possible */
      @for $i from 0 through ($columns - 1) {
        @if not($infix == '' and $i == 0) {
          /* Avoid emitting useless .offset-0 */
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
      .order#{$infix}-first-only {
        order: -1;
      }

      .order#{$infix}-last-only {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i}-only {
          order: $i;
        }
      }

      /* Grid Offset Only */
      @for $i from 0 through ($columns - 1) {
        @if not($infix == '' and $i == 0) {
          /* Avoid emitting useless .offset-0 */
          .offset#{$infix}-#{$i}-only {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

@mixin make-grid-columns-print(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $grid-breakpoints-print: $grid-breakpoints-print
) {
  @each $breakpoint in map.keys($grid-breakpoints-print) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints-print);

    /* Allow columns to stretch full width below their breakpoints */
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @include grid-column-mixin($gutter);
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @include grid-column-mixin($gutter);
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints-print) {
      /* Provide basic `.col-{bp}` classes for equal-width flexbox columns */
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;

        /* Reset earlier grid tiers */
        max-width: 100%;
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      /* `$columns - 1` because offsetting by the width of an entire row isn't possible */
      @for $i from 0 through ($columns - 1) {
        @if not($infix == '' and $i == 0) {
          /* Avoid emitting useless .offset-0 */
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $grid-breakpoints-print) {
      .order#{$infix}-first-only {
        order: -1;
      }

      .order#{$infix}-last-only {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i}-only {
          order: $i;
        }
      }
    }
  }
}
