/*
 * CB Select
 */

@use 'apricot-variable' as *;
@use '../mixins/utils' as *;

.cb-select {
  position: relative;
  width: 100%;

  &.cb-disabled {
    label,
    .cb-glyph,
    .cb-icon {
      color: $gray2 !important;
    }
  }

  label {
    &.cb-disabled {
      color: $gray2;
    }
  }

  .cb-select-container {
    position: relative;
    width: 100%;

    select {
      &.cb-replaced {
        position: relative;
        z-index: 10;

        width: 100%;
        height: 3rem;

        opacity: 0;
        border: 0;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    span {
      &.cb-select {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 3rem;

        > span {
          display: block;

          height: 3rem;
          padding: 11px 15px;

          line-height: 1.5em;

          background-color: $white;
          border: 1px solid $gray4;
          border-radius: $border-radius-input;

          @include border-box();
        }

        span {
          span {
            position: relative;

            display: block;

            height: 24px;

            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .cb-select-arrow {
          position: absolute;
          top: -9px;
          right: -15px;

          display: block;

          width: 42px;
          height: 42px;

          font-size: 0.6875rem;
          font-weight: 700;

          &::before {
            position: absolute;
            top: 17px;
            left: 13px;
          }
        }

        &.focus {
          > span {
            padding: 11px 15px;
            border: 1px solid $input-focus;
            box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
          }
        }

        /* disabled */
        &.disabled {
          > span {
            cursor: default;
            color: $gray2;

            /* iOS fix for unreadable disabled content */
            opacity: 1;
          }
        }
      }
    }
  }

  &.cb-not-active {
    option[disabled][hidden] {
      display: none;
      visibility: hidden;
    }
  }
}

/* ------------------------------------ CONDENSED */
.cb-select {
  &.cb-input-condensed,
  &.cb-condensed {
    font-size: $font-size-small;
    line-height: 1.7143em;

    .cb-select-container {
      select {
        &.cb-replaced {
          height: 2.5rem;
        }
      }

      span {
        &.cb-select {
          height: 2.5rem;

          > span {
            height: 2.5rem;
            padding: 7px 15px;
          }

          .cb-select-arrow {
            top: -9px;
            right: -15px;
          }

          &.focus {
            > span {
              padding: 7px 15px;
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------ MULTI SELECT */

.cb-select[multiple] {
  padding: 11px 0;
  background-color: $white;
  border: 1px solid $gray4;
  border-radius: $border-radius-input;

  option {
    display: flex;
    align-items: center;
    height: 1.5em;
    padding: 0 15px;

    &:hover {
      background-color: $gray5;
      box-shadow: inset 2px 0 0 0 $black1;
    }
  }

  &:focus {
    border: 1px solid $input-focus;
    box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
  }
}
