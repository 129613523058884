/*
 * CB Tables
 */

@use 'sass:map';

@use 'apricot-variable' as *;
@use '../mixins/table' as *;
@use '../mixins/breakpoint' as *;

/* ------------------------------------ BASE */
.cb-table {
  @include table();

  /* Table Utility classes */
  &.cb-no-border {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;

          &:first-child {
            border-left: 0;
          }
        }

        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }

    &.cb-table-light {
      > thead {
        > tr {
          &:last-child {
            > th,
            > td {
              border-bottom: 2px solid $table-border-color;
            }
          }
        }
      }
    }
  }

  &.cb-no-border-left-right {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-right: 0;
          border-left: 0;
        }

        &:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }

    &.cb-table-light {
      > thead {
        > tr {
          &:last-child {
            > th,
            > td {
              border-bottom: 2px solid $table-border-color;
            }
          }
        }
      }
    }
  }

  &.cb-table-condensed {
    th,
    td {
      padding: $table-condensed-cell-padding;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &.cb-table-striped {
    > tbody > tr {
      &:nth-of-type(even) {
        background-color: $white;
      }

      &:nth-of-type(odd) {
        background-color: $gray5;
      }
    }
  }

  &.cb-table-striped-reverse {
    > tbody > tr {
      &:nth-of-type(odd) {
        background-color: $white;
      }

      &:nth-of-type(even) {
        background-color: $gray5;
      }
    }
  }

  /* ------------------------------------ CELL */
  .cb-table-sort {
    padding: 0 !important;

    vertical-align: middle;

    background: $white;
    border-right-width: 0;
    border-left-width: 0;

    a {
      display: block;
      padding: 5px 10px;
      text-decoration: none !important;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }

      .cb-sort-desc,
      .cb-sort-asc {
        color: $blue5;
      }

      .cb-sort {
        color: $black1;
      }

      &:hover {
        cursor: pointer;
        background-color: $blue5-tint1;
      }
    }

    &:first-of-type {
      border-left-width: 1px;
    }

    &:last-of-type {
      border-right-width: 1px;
    }
  }

  &.cb-table-condensed {
    .cb-table-sort {
      a {
        padding: $table-condensed-cell-padding;
      }
    }
  }

  .cb-table-sort-joined {
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: $white;
      text-decoration: none !important;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: $white;

        span {
          &:nth-of-type(1) {
            text-decoration: underline;
          }

          &.cb-glyph,
          &.cb-icon {
            text-decoration: none !important;
          }
        }
      }

      .cb-sort-desc,
      .cb-sort-asc,
      .cb-sort {
        color: $white;
      }
    }

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $black1;

      a {
        color: $white !important;

        .cb-glyph,
        .cb-icon {
          color: $white !important;
        }
      }
    }
  }

  &.cb-table-light {
    .cb-table-sort-joined {
      a {
        color: $black1 !important;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: $black1;

          span {
            &:nth-of-type(1) {
              text-decoration: underline;
            }

            &.cb-glyph,
            &.cb-icon {
              text-decoration: none !important;
            }
          }
        }

        .cb-sort-desc,
        .cb-sort-asc {
          color: $blue5;
        }

        .cb-sort {
          color: $black1;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $blue5-tint1;

        a,
        .cb-sort,
        .cb-glyph,
        .cb-icon {
          color: $black1 !important;
        }

        .cb-sort-desc,
        .cb-sort-asc {
          color: $blue5 !important;
        }
      }
    }
  }

  .cb-table-thumbnail {
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    width: 72px;
    height: 72px;

    background-color: $gray3;
    border: 1px solid $table-border-color;

    &::before {
      content: '?';

      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 1.5rem;
      color: $table-border-color;
    }

    img {
      position: absolute;
      z-index: 2;
      width: 100%;
    }

    & + p {
      float: left;
      display: inline-block;
    }
  }

  .cb-table-tip {
    &.cb-glyph,
    &.cb-icon {
      float: right;
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1em;
    }
  }

  .cb-table-callout {
    background-color: $gray5;
  }

  > thead {
    .cb-table-callout,
    &.cb-table-callout {
      color: $black1 !important;
    }
  }

  .cb-table-highlight {
    background-color: $yellow2 !important;
  }

  .cb-table-selected {
    background-color: $blue5-tint1 !important;
  }

  &.cb-table-expandable {
    .cb-table-expandable-trigger {
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 1px;
      padding: 0 !important;

      .cb-btn {
        height: 100% !important;
        border: 0;
        border-radius: 0;

        &:focus {
          box-shadow: inset 0 0 0 2px $black1;
        }

        &:hover {
          background: rgb(0 0 0 / 16%);
          box-shadow: none;
        }
      }
    }

    .cb-table-expandable-row {
      td {
        &.cb-table-expandable-trigger-right {
          padding-right: 56px;
        }

        &.cb-table-expandable-trigger-left {
          padding-left: 56px;
        }
      }
    }

    &.cb-table-condensed {
      .cb-table-expandable-trigger {
        width: 32px;
        min-width: 32px;
        max-width: 32px;

        .cb-btn {
          width: 32px;
          height: 32px;
          padding: 0;

          font-size: $font-size-small;
          line-height: 0.875em;
        }
      }

      .cb-table-expandable-row {
        td {
          &.cb-table-expandable-trigger-right {
            padding-right: 36px;
          }

          &.cb-table-expandable-trigger-left {
            padding-left: 36px;
          }
        }
      }
    }

    &.cb-table-striped,
    &.cb-table-striped-reverse {
      tbody {
        tr {
          background-color: $white;
        }

        .cb-stripe {
          background-color: $gray5;
        }

        .cb-no-stripe {
          background-color: $white;
        }
      }
    }
  }

  th {
    &.cb-no-padding-th {
      padding: 0 !important;
    }
  }

  td {
    &.cb-no-padding-td {
      padding: 0 !important;
    }
  }

  .cb-table-cell-padding {
    padding: $table-cell-padding;
  }

  .cb-table-condensed-cell-padding {
    padding: $table-condensed-cell-padding;
  }
}

/* ------------------------------------ RESPONSIVE */
.cb-table-responsive {
  overflow-x: auto;
  display: block;
  width: 100%;

  -webkit-overflow-scrolling: touch;

  /* Prevent double border on horizontal scroll due to use of `display: block;` */
  > .cb-table {
    border: 0;
  }
}

.cb-table-responsive {
  @each $breakpoint in map.keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        overflow-x: auto;
        display: block;
        width: 100%;

        -webkit-overflow-scrolling: touch;

        /* Prevent double border on horizontal scroll due to use of `display: block;` */
        > .cb-table {
          border: 0;
        }
      }
    }
  }
}

/* ------------------------------------ SCROLLBAR */
.cb-table-scroll {
  overflow: auto;
}

/* ------------------------------------ MOBILE */
.cb-table-mobile {
  td {
    width: 50%;
  }

  .cb-last-row {
    td,
    th {
      padding-bottom: 24px;
      border-bottom-color: $gray2;
    }

    &:last-of-type {
      td,
      th {
        border-bottom-color: $gray2;
      }
    }

    & + tr {
      td,
      th {
        padding-top: 24px;
      }
    }
  }
}

/* ------------------------------------ STICKY PLUGIN */
.cb-table-sticky {
  &.cb-active-sticky {
    position: relative;
    overflow: auto;
    border: 1px solid $table-border-color;
    border-top: 0;

    .cb-table {
      border-bottom: 0 !important;

      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }

      > caption {
        border-right: 0;
        border-left: 0;
      }

      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

      thead {
        .cb-sticky-cell {
          color: $white;
          background: $gray1;
          border-right: 0;
        }

        &.cb-active-header {
          box-shadow: 0 4px 4px -2px rgb(0 0 0 / 10%);
        }

        &.cb-active-column {
          tr {
            &.cb-sticky-cell {
              border-right: 0;
            }

            .cb-sticky-cell-last {
              border-right: 1px solid $table-border-color;
              box-shadow: 4px 0 4px -2px rgb(0 0 0 / 10%);
            }
          }
        }
      }

      tbody {
        .cb-sticky-cell {
          background: $white;
          border-right: 0;
        }

        &.cb-active-column {
          .cb-sticky-cell-last {
            border-right: 1px solid $table-border-color;
            box-shadow: 4px 0 4px -2px rgb(0 0 0 / 10%);
          }
        }
      }
    }
  }
}

/* ------------------------------------ STICKY CSS */
.cb-table-sticky-css {
  overflow: auto;
  display: block;
  width: 100%;
  border: 1px solid $table-border-color;

  .cb-table {
    position: relative;
    border-spacing: 0;
    border-collapse: separate;

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top: 0;
          border-right: 0;

          &:first-child {
            border-left: 0;
          }
        }
      }
    }

    thead {
      &.cb-active-header {
        tr {
          th {
            position: sticky;
            z-index: 3;
            top: -1px;
            left: 0;

            background: $gray1;
            box-shadow: 0 4px 4px -2px rgb(0 0 0 / 10%);

            &.cb-active-column {
              z-index: 4;
              box-shadow: none;
            }
          }
        }
      }
    }

    tbody {
      &.cb-active-column {
        tr {
          th,
          td {
            &:nth-child(1) {
              position: sticky;
              z-index: 3;
              top: -1px;
              left: 0;

              background: $white;
              border-right: 1px solid $table-border-color;
              box-shadow: 4px 0 4px -2px rgb(0 0 0 / 10%);
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------ Related to React Components */

.cb-loader-wrapper {
  position: absolute;
  z-index: 50000;

  display: flex;
  align-items: top;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding-top: 72px;
}

.cb-table-wrapper {
  &.with-top-scroll {
    overflow-x: auto;
  }
}

.cb-table-top-scroll-holder {
  overflow: scroll hidden;
  display: block;

  .cb-table-top-scroll {
    height: 1px;
  }
}

.cb-table-react {
  &.cb-table {
    tbody {
      th {
        font-weight: initial;
      }
    }
  }

  /* sticky header relative to page */
  &.cb-table-header {
    position: absolute;

    .cb-table-wrapper {
      overflow-x: hidden;
    }

    &.cb-sticky-table {
      position: fixed;
      z-index: 900;
      top: 0;
    }

    table {
      width: unset;

      td.cb-table-sort a.focused {
        outline: $outline-color auto 1px;
        outline-offset: 1px;
      }
    }
  }

  /* sticky header relative to parent
   * thead tag
   */
  .cb-sticky-header-parent {
    position: sticky;
    z-index: 2;
    top: -1px;

    &.cb-sticky-active {
      box-shadow: 0 4px 4px -2px rgb(0 0 0 / 10%);
    }

    .cb-table-sort:first-of-type {
      border-left: 0 !important;
      box-shadow: inset 1px 0 0 $table-border-color;
    }
  }

  /* sticky column relative to parent
   * table tag
   */
  &.cb-sticky-table-column {
    > thead {
      th {
        background-color: $gray1;
        border: 0;
        box-shadow:
          inset -1px 0 0 $table-border-color,
          inset 0.1px 0 0 $table-border-color;

        &:first-of-type {
          border-right: 0 !important;
          box-shadow: inset -1px 0 0 $white;

          + th {
            border-left: 0 !important;
          }
        }
      }
    }

    .cb-sticky-column {
      position: sticky;
      z-index: 1;
      left: 0;

      background-color: $white;
      border-right: 0 !important;
      border-left: 0 !important;
      box-shadow:
        inset -1px 0 0 $table-border-color,
        inset 1px 0 0 $table-border-color;

      + th,
      + td {
        border-left: 0 !important;
      }
    }

    .cb-stripe {
      .cb-sticky-column {
        background-color: $gray5 !important;
      }
    }

    &.cb-no-border {
      .cb-sticky-column {
        box-shadow: inset -1px 0 0 $table-border-color !important;
      }
    }

    > thead {
      th,
      .cb-table-sort {
        &:first-child {
          position: sticky;
          z-index: 2;
          left: 0;
        }
      }
    }
  }
}

/* FF Only */
@document url-prefix() {
  .cb-table-react {
    .cb-sticky-table-column {
      .cb-sticky-column {
        box-shadow:
          inset 0 -0.7px 1px $table-border-color,
          inset -1px 0 0 $table-border-color !important;
      }
    }
  }
}
