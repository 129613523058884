/*
 * CB Pagination
 */

@use 'apricot-variable' as *;
@use '../mixins/link' as *;
@use '../mixins/breakpoint' as *;

.cb-pagination {
  display: inline-flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;

  font-size: 0;

  a {
    @include black-link();

    &:active,
    &.cb-active {
      color: $black1;
    }

    &.cb-disabled,
    &:disabled {
      color: $gray3;
    }
  }

  & > li {
    display: inline-flex;

    .cb-btn-square {
      border-color: $gray4;
      border-right-width: 0;
      border-left-width: 0;
      border-radius: 0;

      &.cb-active {
        background-color: $gray3;
        border: 1px solid $gray4 !important;
      }

      &:focus,
      &.cb-focus {
        border: 1px solid $black1;
      }

      &:disabled,
      &.cb-disabled {
        &.cb-active,
        &.active {
          color: $gray5;
        }
      }
    }

    &:first-child {
      .cb-btn-square {
        border-left-width: 1px;
        border-top-left-radius: $btn-border-radius-input;
        border-bottom-left-radius: $btn-border-radius-input;
      }
    }

    &:last-child {
      .cb-btn-square {
        border-right-width: 1px;
        border-top-right-radius: $btn-border-radius-input;
        border-bottom-right-radius: $btn-border-radius-input;
      }
    }
  }
}

// Default
.cb-page-size {
  display: inline-flex;
  flex-wrap: wrap;

  > span {
    margin-right: 8px;

    + span {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  a {
    @include palette-link($blue5, $blue5);

    &:disabled {
      color: $gray3;
    }

    &.cb-active {
      font-weight: 700;
      color: $link-color-black;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:hover,
      &:focus,
      &:visited {
        color: $link-color-black;
      }

      &:active {
        color: $link-active-color-black;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-flex;

      &::after {
        content: '\007C';
        display: inline-flex;
        padding: 0 8px;
        color: $black1;
      }

      &:last-of-type {
        &::after {
          content: '';
          padding: 0;
        }
      }
    }
  }

  nav {
    margin-right: 8px;
  }
}

// Select
.cb-page-size{
  .cb-select {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    label {
      flex-wrap: nowrap;
      margin-right: 8px;
    }

    .cb-select-container {
      flex-grow: 1; // This will make the second child stretch to fill the remaining width
      width: auto;
      min-width: 96px; // accommodate for a 2 digit page number
    }
  }
}

.cb-pagination-size,
.cb-pagination-size-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@include media-breakpoint-down('sm', $grid-breakpoints) {
  .cb-pagination-size-select {
    display: flex;
    flex-wrap: wrap;

    .cb-pagination-info {
      flex: 1 1 40%;
      order:1; // First child
    }

    nav {
      display: flex;
      flex: 1 1 100%;
      justify-content: center; 
      order: 3; // Second child
    }

    .cb-page-size {
      flex: 1 1 40%;
      order:2; // Third child
    }
  }
}