/*
 * CB Back To Top
 */

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;

.cb-back-to-top {
  position: fixed;
  right: 24px;
  bottom: 24px;
  display: none;

  &.cb-back-to-top-show {
    display: block;
  }

  &.cb-back-to-top-hide {
    display: none;
  }
}
