/*
 * CB Button Mixins
 */

@use '../includes/apricot-variable' as *;

/* Button sizes */
@mixin button-size($padding-y, $padding-x) {
  padding: $padding-y - 1px $padding-x - 1px;
}

@mixin button-active($background, $border: $btn-border-color, $color: $btn-font-color, $decoration: none) {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).cb-active {
    color: $color;
    text-decoration: $decoration;
    background-color: $background;
    border-color: $border;
  }
}

@mixin button-disabled($background, $border, $color: $btn-font-color, $opacity: $btn-disabled-opacity) {
  &:not(.cb-btn-pulse) {
    &.cb-disabled,
    &:disabled {
      cursor: not-allowed;

      color: $color !important;
      text-decoration: none !important;

      opacity: $opacity;
      background-color: $background;
      border-color: $border;
      box-shadow: none;

      span {
        text-decoration: none !important;
      }
    }
  }
}

/* Loading Dots */
@mixin dot(
  $width: $dot-width,
  $height: $dot-height,
  $radius: $dot-radius,
  $bgColor: $dot-bg-color,
  $color: $dot-color
) {
  width: $width;
  height: $height;

  color: $color;

  background-color: $bgColor;
  border-radius: $radius;
}

@mixin light() {
  color: $white;
  border-color: $white;

  &:hover,
  &.cb-hover {
    background-color: rgb(0 0 0 / 25%);
    box-shadow: inset 0 0 0 1px $white;
  }

  &:focus,
  &.cb-focus {
    text-decoration: underline;
    background-color: transparent;
    border-color: $white;
    box-shadow: inset 0 0 0 2px $white;
  }

  &.cb-disabled,
  &:disabled {
    text-decoration: none !important;

    &:not(.cb-btn-pulse) {
      color: rgb(255 255 255 / 40%);
      background-color: rgb(255 255 255 / 10%);
      border-color: transparent;
    }

    & {
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
  }
}

@mixin primary-light() {
  color: $blue5;
  background-color: $white;
  border-color: $white;

  &:hover,
  &.cb-hover {
    color: $white;
    background-color: rgb(0 0 0 / 25%);
    box-shadow: inset 0 0 0 1px $white;
  }

  &:focus,
  &.cb-focus {
    color: $blue5;
    border-color: $blue5;
    box-shadow: 0 0 0 3px $white;
  }

  &.cb-disabled,
  &:disabled {
    &:not(.cb-btn-pulse) {
      cursor: not-allowed;

      color: rgb(255 255 255 / 40%);
      text-decoration: none !important;

      background-color: rgb(255 255 255 / 10%);
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:not(.cb-btn-pulse) {
    &:hover:not(:focus),
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }

    &:focus:not(:hover),
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $blue5;
        }
      }
    }
  }

  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      color: $value;
    }
  }
}

@mixin yellow-light() {
  color: $black1;
  background-color: $white;
  border-color: $white;

  &:hover,
  &.cb-hover {
    color: $white !important;
    background-color: rgb(0 0 0 / 25%);
    box-shadow: inset 0 0 0 1px $white;
  }

  &:focus,
  &.cb-focus {
    color: $black1;
    border-color: $black1;
    box-shadow: 0 0 0 3px $white;
  }

  &.cb-disabled,
  &:disabled {
    &:not(.cb-btn-pulse) {
      cursor: not-allowed;

      color: rgb(255 255 255 / 40%) !important;
      text-decoration: none !important;

      background-color: rgb(255 255 255 / 10%);
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:not(.cb-btn-pulse) {
    &:hover:not(:focus),
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }

    &:focus:not(:hover),
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $black1;
        }
      }
    }
  }
}

@mixin close {
  width: 2em;
  height: 2rem;
  padding: 0;

  font-size: 1rem;
  line-height: 1em;

  border: transparent;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    font-size: $font-size-small;
    line-height: 0.875em;
  }

  @include button-disabled(transparent, transparent, $gray2);

  &:focus,
  &.cb-focus {
    background-color: transparent !important;
  }

  &.cb-white-color {
    background: transparent;

    &:hover {
      background-color: $black1 !important;
    }
  }
}
