/*
 * CB SVG React Illustrations
 */

.cb-svg-ilus {
  position: relative;

  svg {
    position: absolute;
    top: calc(50% - (100% - 24px) / 2);
    left: calc(50% - (100% - 16px) / 2);

    width: calc(100% - 16px);
    height: calc(100% - 16px);
  }

  &.cb-svg-ilus-with-circular,
  &.cb-svg-ilus-with-shadow {
    svg {
      &.cb-svg-ilus-svg {
        top: 0;
      }

      &.cb-svg-ilus-circular {
        top: calc(50% - (100% - 19px) / 2);
      }

      &.cb-svg-ilus-shadow {
        top: calc(50% - (100% - 19px) / 2);
      }
    }
  }
}
