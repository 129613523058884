/*
 * CB Grid
 */

@use 'sass:map';

@use 'apricot-variable' as *;

@use '../mixins/breakpoint' as *;
@use '../mixins/grid-framework' as *;
@use '../mixins/grid' as *;

html {
  box-sizing: border-box;

  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Container widths
 * Set the container width, and override it for fixed navbars in media queries.
 */
@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

/* Fluid container
 * Utilizes the mixin meant for fixed width containers, but with 100% width for
 * fluid, full width layouts.
 */
@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

/* Row
 * Rows contain and clear the floats of your columns.
 */
@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  /* Remove the negative margin from default .row, then the horizontal padding
   * from all immediate children columns (to prevent runaway style inheritance).
   */
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

/* Columns
 * Common styles for small and large grid columns
 */
@if $enable-grid-classes {
  @include make-grid-columns();
}

/* Utilities for common `display` values */
@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .display#{$infix}-#{$value} {
        display: $value !important;
      }
    }

    @each $value in $positions {
      .position#{$infix}-#{$value} {
        position: $value !important;
      }
    }

    @each $value in $z-index {
      .z-index#{$infix}-#{$value} {
        z-index: $value !important;
      }
    }

    @each $value in $mp-value {
      .cb-gap#{$infix}-#{$value} {
        gap: #{$value}px !important;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .display#{$infix}-#{$value}-only {
        display: $value !important;
      }
    }

    @each $value in $positions {
      .position#{$infix}-#{$value}-only {
        position: $value !important;
      }
    }

    @each $value in $z-index {
      .z-index#{$infix}-#{$value}-only {
        z-index: $value !important;
      }
    }
  }
}

@each $value in $displays {
  .display-#{$value} {
    display: $value !important;
  }
}

@each $value in $positions {
  .position-#{$value} {
    position: $value !important;
  }
}

@each $value in $positions-org {
  .position-#{$value} {
    #{$value}: 0 !important;
  }
}

@each $value in $z-index {
  .z-index-#{$value} {
    z-index: $value !important;
  }
}

@each $value in $mp-value {
  .cb-gap-#{$value} {
    gap: #{$value}px;
  }
}

/* Utilities for common `hidden` values and gap */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .hidden-#{$breakpoint}-only,
    .hidden-#{$breakpoint},
    .cb-hidden-#{$breakpoint} {
      display: none !important;
    }

    @each $value in $mp-value {
      .cb-gap-#{$breakpoint}-#{$value}-only {
        gap: #{$value}px !important;
      }
    }
  }

  /* Range */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .hidden-#{$breakpoint}-up,
    .cb-hidden-#{$breakpoint}-up {
      display: none !important;
    }

    @each $value in $mp-value {
      .cb-gap-#{$breakpoint}-#{$value}-up {
        gap: #{$value}px !important;
      }
    }
  }

  /* Range */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .hidden-#{$breakpoint}-down,
    .cb-hidden-#{$breakpoint}-down {
      display: none !important;
    }

    @each $value in $mp-value {
      .cb-gap-#{$breakpoint}-#{$value}-down {
        gap: #{$value}px !important;
      }
    }
  }
}

.cb-hidden,
.hidden {
  display: none !important;
}

/* Flex variation
 * Custom styles for additional flex alignment options.
 */
@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row {
      flex-direction: row !important;
    }

    .flex#{$infix}-column {
      flex-direction: column !important;
    }

    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }

    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }

    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }

    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }

    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }

    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }

    .justify-content#{$infix}-center {
      justify-content: center !important;
    }

    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }

    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }

    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }

    .align-items#{$infix}-center {
      align-items: center !important;
    }

    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }

    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }

    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }

    .align-content#{$infix}-center {
      align-content: center !important;
    }

    .align-content#{$infix}-between {
      align-content: space-between !important;
    }

    .align-content#{$infix}-around {
      align-content: space-around !important;
    }

    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }

    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }

    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }

    .align-self#{$infix}-center {
      align-self: center !important;
    }

    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }

    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row-only {
      flex-direction: row !important;
    }

    .flex#{$infix}-column-only {
      flex-direction: column !important;
    }

    .flex#{$infix}-row-reverse-only {
      flex-direction: row-reverse !important;
    }

    .flex#{$infix}-column-reverse-only {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap-only {
      flex-wrap: wrap !important;
    }

    .flex#{$infix}-nowrap-only {
      flex-wrap: nowrap !important;
    }

    .flex#{$infix}-wrap-reverse-only {
      flex-wrap: wrap-reverse !important;
    }

    .flex#{$infix}-fill-only {
      flex: 1 1 auto !important;
    }

    .flex#{$infix}-grow-0-only {
      flex-grow: 0 !important;
    }

    .flex#{$infix}-grow-1-only {
      flex-grow: 1 !important;
    }

    .flex#{$infix}-shrink-0-only {
      flex-shrink: 0 !important;
    }

    .flex#{$infix}-shrink-1-only {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start-only {
      justify-content: flex-start !important;
    }

    .justify-content#{$infix}-end-only {
      justify-content: flex-end !important;
    }

    .justify-content#{$infix}-center-only {
      justify-content: center !important;
    }

    .justify-content#{$infix}-between-only {
      justify-content: space-between !important;
    }

    .justify-content#{$infix}-around-only {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start-only {
      align-items: flex-start !important;
    }

    .align-items#{$infix}-end-only {
      align-items: flex-end !important;
    }

    .align-items#{$infix}-center-only {
      align-items: center !important;
    }

    .align-items#{$infix}-baseline-only {
      align-items: baseline !important;
    }

    .align-items#{$infix}-stretch-only {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start-only {
      align-content: flex-start !important;
    }

    .align-content#{$infix}-end-only {
      align-content: flex-end !important;
    }

    .align-content#{$infix}-center-only {
      align-content: center !important;
    }

    .align-content#{$infix}-between-only {
      align-content: space-between !important;
    }

    .align-content#{$infix}-around-only {
      align-content: space-around !important;
    }

    .align-content#{$infix}-stretch-only {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto-only {
      align-self: auto !important;
    }

    .align-self#{$infix}-start-only {
      align-self: flex-start !important;
    }

    .align-self#{$infix}-end-only {
      align-self: flex-end !important;
    }

    .align-self#{$infix}-center-only {
      align-self: center !important;
    }

    .align-self#{$infix}-baseline-only {
      align-self: baseline !important;
    }

    .align-self#{$infix}-stretch-only {
      align-self: stretch !important;
    }
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

/* ------------------------------------ ORDER */
.order-first {
  order: -1;
}

.order-last {
  order: $grid-columns + 1;
}

@for $i from 0 through $grid-columns {
  .order-#{$i} {
    order: $i;
  }
}

/* ------------------------------------ Gutter */

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.cb-max-width-content {
  max-width: fit-content;
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    /* ------------------------------------ GUTTER */
    .cb-no-gutters-#{$breakpoint} {
      margin-right: 0;
      margin-left: 0;

      > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.cb-flex-row-break {
  display: flex;
  flex-wrap: wrap;
}

.cb-flex-column-break {
  flex-basis: 100%;
  width: 0;
}

/* ------------------------------------ VERTICAL/HORIZONTAL GUTTER */
@each $size in $gutter-size {
  .cb-gutterv-#{$size} {
    flex-flow: wrap;
    margin-top: -#{$size}px;

    > div,
    > li {
      margin-top: #{$size}px;
    }
  }

  .cb-gutterh-#{$size} {
    flex-flow: wrap;
    margin-left: -#{$size}px;

    > div,
    > li {
      margin-left: #{$size}px;
    }
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $gutter-size {
      .cb-gutterv-#{$breakpoint}-#{$size} {
        flex-flow: wrap;
        margin-top: -#{$size}px;

        > div,
        > li {
          margin-top: #{$size}px;
        }
      }

      .cb-gutterh-#{$breakpoint}-#{$size} {
        flex-flow: wrap;
        margin-left: -#{$size}px;

        > div,
        > li {
          margin-left: #{$size}px;
        }
      }
    }
  }

  /* Range */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $gutter-size {
      .cb-gutterv-#{$breakpoint}-up-#{$size} {
        flex-flow: wrap;
        margin-top: -#{$size}px;

        > div,
        > li {
          margin-top: #{$size}px;
        }
      }

      .cb-gutterh-#{$breakpoint}-up-#{$size} {
        flex-flow: wrap;
        margin-left: -#{$size}px;

        > div,
        > li {
          margin-left: #{$size}px;
        }
      }
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $gutter-size {
      .cb-gutterv-#{$breakpoint}-down-#{$size} {
        flex-flow: wrap;
        margin-top: -#{$size}px;

        > div,
        > li {
          margin-top: #{$size}px;
        }
      }

      .cb-gutterh-#{$breakpoint}-down-#{$size} {
        flex-flow: wrap;
        margin-left: -#{$size}px;

        > div,
        > li {
          margin-left: #{$size}px;
        }
      }
    }
  }
}

/* ------------------------------------ FLOAT */
.cb-float-left {
  float: left;
}

.cb-float-right {
  float: right;
}

.cb-float-none {
  float: none;
}

.cb-clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

/* ------------------------------------ WIDTH */
@each $class, $value in $width-height-opt {
  .cb-width-#{$class} {
    width: $value !important;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    @each $class, $value in $width-height-opt {
      .cb-width#{$infix}-#{$class}-up {
        width: $value;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $class, $value in $width-height-opt {
      .cb-width#{$infix}-#{$class}-only {
        width: $value;
      }
    }
  }
}

/* ------------------------------------ HEIGHT */

@each $class, $value in $width-height-opt {
  .cb-height-#{$class} {
    height: $value !important;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    @each $class, $value in $width-height-opt {
      .cb-height#{$infix}-#{$class}-up {
        height: $value;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $class, $value in $width-height-opt {
      .cb-height#{$infix}-#{$class}-only {
        height: $value;
      }
    }
  }
}

/* ------------------------------------ HEIGHT, WIDTH - RESET */
.cb-no-min-height {
  min-height: auto !important;
}

.cb-no-max-height {
  max-height: auto !important;
}

.cb-no-min-width {
  min-width: auto !important;
}

.cb-no-max-width {
  max-width: auto !important;
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    .cb-no-min-height#{$infix}-up {
      min-height: auto !important;
    }
    .cb-no-max-height#{$infix}-up {
      max-height: auto !important;
    }
    .cb-no-min-width#{$infix}-up {
      min-width: auto !important;
    }
    .cb-no-max-width#{$infix}-up {
      max-width: auto !important;
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .cb-no-min-height#{$infix}-only {
      min-height: auto !important;
    }
    .cb-no-max-height#{$infix}-only {
      max-height: auto !important;
    }
    .cb-no-min-width#{$infix}-only {
      min-width: auto !important;
    }
    .cb-no-max-width#{$infix}-only {
      max-width: auto !important;
    }
  }
}

/* ------------------------------------ WIDTH VIEWPORT */
@each $class, $value in $width-vw-opt {
  .cb-width-#{$class}-vw {
    width: $value;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    @each $class, $value in $width-vw-opt {
      .cb-width#{$infix}-#{$class}-vw-up {
        min-width: $value;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $class, $value in $width-height-opt {
      .cb-width#{$infix}-#{$class}-only {
        min-width: $value;
      }
    }
  }
}

/* ------------------------------------ HEIGHT VIEWPORT */
@each $class, $value in $height-vh-opt {
  .cb-height-#{$class}-vh {
    min-height: $value;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    @each $class, $value in $height-vh-opt {
      .cb-height#{$infix}-#{$class}-vh-up {
        min-height: $value;
      }
    }
  }

  @include media-breakpoint-only($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $class, $value in $height-vh-opt {
      .cb-height#{$infix}-#{$class}-vh-only {
        height: $value;
      }
    }
  }
}

/* ------------------------------------ POSITION TOP/BOTTOM */
@each $value in $positions-org {
  .position-#{$value} {
    #{$value}: 0 !important;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $positions-org {
      .position-#{$breakpoint}-#{$value}-only {
        #{$value}: 0 !important;
      }
    }
  }

  /* Range */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $positions-org {
      .position-#{$breakpoint}-#{$value} {
        #{$value}: 0 !important;
      }
    }
  }
}

/* ------------------------------------ OVERFLOW */
@each $value in $overflows {
  .overflow-#{$value} {
    overflow: $value;
  }
  .overflow-x-#{$value} {
    overflow-x: $value;
  }
  .overflow-y-#{$value} {
    overflow-y: $value;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $overflows {
      .overflow-#{$breakpoint}-#{$value}-only {
        overflow: $value;
      }
      .overflow-x-#{$breakpoint}-#{$value}-only {
        overflow-x: $value;
      }
      .overflow-y-#{$breakpoint}-#{$value}-only {
        overflow-y: $value;
      }
    }
  }

  /* Range */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $positions-org {
      @each $value in $overflows {
        .overflow-#{$breakpoint}-#{$value} {
          overflow: $value;
        }
        .overflow-x-#{$breakpoint}-#{$value} {
          overflow-x: $value;
        }
        .overflow-y-#{$breakpoint}-#{$value} {
          overflow-y: $value;
        }
      }
    }
  }
}

/* ------------------------------------ OVERFLOW */
@each $value in $visibility {
  .visibility-#{$value} {
    visibility: $value;
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $visibility {
      .overflow-#{$breakpoint}-#{$value}-only {
        visibility: $value;
      }
    }
  }

  /* Range */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $positions-org {
      @each $value in $visibility {
        .visibility-#{$breakpoint}-#{$value} {
          visibility: $value;
        }
      }
    }
  }
}
