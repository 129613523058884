/*
 * CB Popover
 */

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/font' as *;

.cb-popover {
  position: absolute;
  z-index: $z-index-tooltip;

  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;

  color: $black1;
  text-align: left;

  opacity: 0;
  background-color: $white;
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0 0 24px rgb(0 0 0 / 20%);

  transition: opacity $transition-time-l ease-in-out;

  /* mobile */
  @include media-breakpoint-down('xs', $grid-breakpoints) {
    max-width: calc(100vw - 48px);
  }

  &[aria-hidden='false'] {
    display: block;
  }

  &[aria-hidden='true'] {
    display: none;
  }

  &[data-popper-placement^='top'],
  &[data-popper-placement^='bottom'],
  &[data-popper-placement^='right'],
  &[data-popper-placement^='left'] {
    opacity: 1;
  }

  .cb-popover-inner {
    padding: 24px;

    .cb-popover-header {
      margin-bottom: 24px;

      .cb-popover-title {
        font-size: 1.4375rem;
        font-weight: 400;
        line-height: 1.0435em;
      }

      &.cb-popover-close {
        display: flex;
        justify-content: space-between;

        .cb-popover-title {
          line-height: 32px;
        }
      }
    }

    .cb-popover-footer {
      display: flex;
      justify-content: space-between;

      margin-top: 16px;
      padding-top: 16px;

      border-top: 1px solid $gray4;

      .cb-btn-naked {
        &.cb-no-padding {
          align-self: center;
        }
      }
    }
  }
}

.cb-btn-row {
  .cb-popover {
    font-size: 1rem;

    .cb-btn {
      flex-shrink: unset;
      margin: 0;
    }
  }
}

/* ------------------------------------ React Component */

.cb-drupal-popover {
  .cb-btn-circle {
    &:focus,
    &:hover {
      border: 1px solid $white;
      box-shadow: 0 0 0 2px $blue5;
    }
  }

  &.cb-popover-light {
    .cb-btn-circle {
      color: $black1 !important;
      background-color: $white !important;
      border: 1px solid $white !important;

      &:focus,
      &:hover {
        border: 1px solid $black1 !important;
        box-shadow: 0 0 0 2px $white;
      }
    }
  }
}
