/*
 * CB Checkable Menus
 */

@use 'apricot-variable' as *;

.cb-menu-item-radio {
  padding: 0;
  list-style: none;

  button {
    position: relative;

    padding-left: 32px;

    text-align: left;

    background: transparent;
    border: 0;

    &::before {
      cursor: pointer;
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      box-sizing: border-box;
      width: 24px;
      min-width: 24px;
      height: 24px;

      background-color: $white;
      border: 1px solid $black1;
      border-radius: 50%;
      outline: none;

      transition: all 0.2 ease-out;
    }

    &::after {
      content: '';

      position: absolute;
      top: 12px;
      left: 12px;

      display: block;

      box-sizing: border-box;
      width: 0;
      height: 0;

      opacity: 1;
      background-color: $blue5;
      border: 0 solid $blue5;
      border-radius: 50%;

      transition: all $transition-time ease-out;
    }

    &[aria-checked='true'] {
      &::before {
        background-color: $white;
        border-color: $blue5;
        border-width: 2px;
      }

      &::after {
        top: 5px;
        left: 5px;

        width: 0;
        height: 0;

        border: 7px solid $blue5;
      }
    }

    &:hover {
      &::before {
        background-color: $gray5;
        border: 1px solid $blue5;
      }
    }

    &:disabled,
    &.cb-disabled {
      &::before {
        cursor: not-allowed !important;
        background: $gray5 !important;
        border: 1px solid $gray4 !important;
      }

      &::after {
        border-color: $gray4;
      }

      &[aria-checked='true'] {
        &::after {
          background: $gray4;
          border-color: $gray4;
        }
      }
    }
  }

  .cb-label-left {
    margin-left: -8px;
    padding: 8px;
    padding-right: 16px;
    background: transparent;

    button {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      text-align: left;

      &::before {
        top: 0;
        right: 0;
        left: auto;
      }

      &::after {
        right: 12px;
        left: auto;
      }

      &[aria-checked='true'] {
        &::after {
          right: 5px;
          left: auto;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background: $gray5;
      border-radius: 4px;
      transition: all $transition-time-s ease-in-out;
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  /* ------------------------------------ Radio Button */
  .cb-menu-item-radio {
    button {
      &:focus {
        outline: none;

        &::before {
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }
}
