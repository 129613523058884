/*
 * CB Stepper Mixins
 */

 @use '../includes//apricot-variable' as *;
 @use '../mixins/glyph' as *;

@mixin cb-v-stepper() {
  ul,
  ol {
    flex-direction: column;

    li {
      display: flex;
      flex-flow: wrap;
      align-items: center;

      &::after {
        top: 38px;
        left: 15px;
        width: 2px;
        height: 100%;
      }

      a {
        display: flex;
        flex-flow: wrap;
        align-items: center;
      }

      & {
        margin-bottom: 44px;
        padding: 0;
        text-align: left;
      }

      p,
      .cb-stepper-label {
        padding-left: 16px;

        & + p,
        & + div {
          flex: 100%;
          padding-left: 48px;
        }
      }

      &:last-child {
        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &.cb-stepper-content-glyph,
  &.cb-stepper-ilus {
    ul,
    ol {
      li {
        margin-bottom: 64px;

        &::after {
          top: 72px;
          left: 31px;
          width: 3px;
          height: calc(100% - 16px);
        }
      }
    }
  }
}

@mixin glyph-multi-content-color-stepper($name, $color1, $color2, $bk: null) {
  &.cb-glyph-#{$name} {
    ul,
    ol {
      li {
        .cb-glyph-content-multi {
          @include glyph-multi-content-color($color1, $color2, $bk);
        }

        &.cb-stepper-skip {
          &::after {
            background: $bk;
          }

          .cb-glyph-content-multi {
            @include glyph-multi-content-color($white, $white, $gray2);
          }
        }

        &.cb-stepper-complete {
          &::after {
            background: $bk;
          }
        }
      }
    }
  }
}
