/*
 * CB Modal Mixins
 */

@use '../includes/apricot-variable' as *;
@use '../mixins/breakpoint' as *;

@mixin modal-title() {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.1429em;

  @include media-breakpoint-up('xs', $grid-breakpoints) {
    font-size: 1.4375rem;
    font-weight: 400;
    line-height: 1.1429em;
  }

  @include media-breakpoint-up('sm', $grid-breakpoints) {
    /* 28 */
    font-size: 1.75rem;
    font-weight: 400;

    /* 32 */
    line-height: 1.1429em;
  }

  /* lg, xl, 2xl, 3xl */
  @include media-breakpoint-up('lg', $grid-breakpoints) {
    /* 32 */
    font-size: 2rem;
    font-weight: 400;

    /* 40 */
    line-height: 1.25em;
  }
}
