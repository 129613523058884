/*
 * CB Date Picker
 */

@use 'sass:string';

@use 'apricot-variable' as *;
@use '../mixins/utils' as *;
@use '../mixins/date-picker' as *;

$selected-date-background-color: $blue5-tint2;
$day-hover-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E  %3Ccircle  stroke='#{str-replace(string.quote(#{$black1}),'#','%23')}' fill='transparent' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
$today-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Ccircle  stroke='#{str-replace(string.quote(#{$blue5-tint2}),'#','%23')}' fill='#{str-replace(string.quote(#{$blue5-tint2}),'#','%23')}' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");

.cb-date-picker {
  width: 306px;
  min-width: 306px;
  max-width: 306px;

  .cb-popover-header {
    display: flex;
    align-items: center;

    .cb-date-picker-month {
      display: inline-flex;
      justify-content: center;

      width: calc(100% - 48px);

      font-size: 19px;
      font-weight: 700;
      line-height: 1.26;

      &:nth-of-type(2) {
        display: none;
      }

      .cb-month-label {
        + .cb-year-label {
          margin-left: 8px;
        }
      }
    }

    .cb-prev-month,
    .cb-next-month {
      color: $black1;
      border: 0;

      &:hover {
        background-color: $blue5-tint1;
        box-shadow: inset 0 0 0 1px $blue5-tint1;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  &.cb-date-picker-double {
    width: 654px;
    min-width: 654px;
    max-width: 654px;

    .cb-popover-header {
      .cb-date-picker-month {
        /* each panel is 280 */
        width: calc(280px - 48px);

        &:nth-of-type(2) {
          display: flex;
          margin-left: 92px;
        }
      }
    }
  }

  .cb-month-container {
    display: flex;
    justify-content: space-between;
  }

  .cb-popover-inner {
    padding-bottom: 16px;
  }
}

.cb {
  &.is-hidden {
    display: none;
  }

  &-month {
    width: ($day-size * 7) + ($month-padding * 2);
    min-width: ($day-size * 7) + ($month-padding * 2);
    padding: $month-padding;
  }

  &-days-of-the-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &-day-of-the-week {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: $day-of-week-font-size;
    font-weight: $day-of-week-font-weight;
  }

  &-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &-day {
    cursor: default;

    display: flex;
    align-items: center;
    justify-content: center;

    height: $day-size;

    font-size: $day-font-size;
    font-weight: $day-font-weight;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    transition: background-image $transition-time-l ease;

    &.is-today {
      color: $today-color;
      background-image: $today-background-image;
    }

    &:not(.is-disabled):hover {
      background-image: $day-hover-background-image;
      background-size: contain;
    }

    &.is-previous-month,
    &.is-next-month {
      opacity: $day-previous-next-opacity;
    }
    &#{&}.is-in-range:not(.is-disabled) {
      opacity: 1;
    }

    &.is-in-range {
      background-color: $blue5-tint2;
      background-image: none;
    }

    &.is-disabled {
      pointer-events: none;
      color: $gray1;
      background: none;
      background-color: $gray5;
    }

    &.is-start-date.is-in-range {
      @include selected-start();
    }

    &.is-start-date.is-available:not(.is-in-range) {
      @include selected-date();
    }

    &.is-end-date.is-in-range {
      @include selected-end();
    }

    &.is-start-date.is-end-date {
      @include selected-date();
    }

    &.is-start-date,
    &.is-end-date,
    &.is-start-date:hover,
    &.is-end-date:hover {
      color: $selected-date-color;
      background-position: center;
      background-size: auto;
    }

    &.is-disabled {
      &.is-start-date,
      &.is-end-date,
      &.is-start-date:hover,
      &.is-end-date:hover {
        color: $gray1;
        background-color: $blue5-tint1;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      width: calc(100% - 4px);
      height: calc(100% - 4px);

      color: inherit !important;
      text-decoration: none;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: inherit !important;
        text-decoration: none;
      }
    }
  }
}

.cb-date-picker {
  &.cb-date-picker-double {
    .cb {
      &-month {
        width: ($day-size-double * 7) + ($month-padding * 2);
        min-width: ($day-size-double * 7) + ($month-padding * 2);
      }

      &-day {
        height: $day-size-double;
      }
    }
  }
}

/* ------------------------------------ DATE PICKER */
.cb-input-date-picker {
  .cb-input {
    min-width: 184px;

    /* disable datePicker with Input */
    &.cb-input-date {
      &.cb-floating-label {
        &.cb-disabled {
          label {
            &:not(.cb-value-fl),
            &:not(.cb-focus-fl) {
              justify-content: start;
              width: calc(100% - 32px);
            }

            &.cb-value-fl,
            &.cb-focus-fl {
              justify-content: center;
              width: auto;
            }
          }
        }
      }
    }

    &.cb-btn-input {
      .cb-btn {
        &:disabled {
          &:hover {
            background-color: transparent;
          }
        }

        &:not(:disabled) {
          &:hover {
            background-color: $gray5;
          }
        }
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m='keyboard'] {
  .cb-date-picker {
    &.cb-popover {
      &:focus {
        box-shadow: inset 0 0 0 2px $blue5;
      }
    }
  }
}
