/*
 * CB Glyph Logos
 */

@use 'apricot-variable' as *;
@use '../mixins/font' as *;
@use '../mixins/glyph' as *;

.cb-glyph-logo {
  position: relative;
  display: inline-block;
  background-image: none !important;

  @include apricot-font(CB Glyphs Logo);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
  }

  @each $key, $value in $logo-glyphs {
    &.cb-#{$key} {
      @include pseudo-content($value);
    }
  }
}
