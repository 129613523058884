/*
 * CB Custom Scrollbar
 */

@use 'apricot-variable' as *;

.cb-scrollbar-container {
  margin-bottom: 24px;

  &.active {
    position: relative;
    overflow: hidden;
    margin-bottom: 96px;
    border: 1px solid $table-border-color;

    .cb-table-responsive {
      position: relative;
      overflow: hidden;
    }

    .cb-table {
      position: absolute;
      border-bottom: 0 !important;

      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }

      > caption {
        border-right: 0;
        border-left: 0;
      }
    }

    .cb-scrollbar {
      position: relative;

      display: block;

      width: 100%;
      height: 14px;
      padding: 2px;

      box-shadow: inset 0 0 6px rgb(0 0 0 / 20%);

      .cb-scrollbar-thumb {
        position: absolute;
        left: 0;

        min-width: 30px;
        height: 10px;

        opacity: 0.4;
        background-color: $gray2;
        border-radius: 8px;
      }

      &:hover {
        .cb-scrollbar-thumb {
          opacity: 0.7;
        }
      }
    }
  }

  .cb-table-responsive {
    overflow-y: hidden;
    margin-bottom: 0;
    border: 0;

    .table {
      margin-bottom: 0;
    }
  }
}

.not-selectable {
  user-select: none;
}
