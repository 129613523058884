/*
 * CB File Upload
 */

@use '../mixins/link' as *;
@use '../mixins/font' as *;

.cb-file-upload {
  input[type='file'] {
    display: none;
    width: 0;
    height: 0;

    /* display:none on the input won't trigger the click event in WebKit.
     * Setting visibility to hidden and width 0 works.
     */
    visibility: hidden;
  }

  .cb-file-element {
    display: block;
    margin-top: 24px;
    line-height: 24px;
    white-space: nowrap;
  }

  .cb-file-element-rm {
    display: block;

    max-width: fit-content;
    margin-top: 24px;

    text-decoration: none;
    white-space: nowrap;

    @include black-link();

    &.cb-glyph {
      cursor: pointer;

      &::before {
        content: '';
      }

      &::after {
        content: '\e955';
        padding-left: 16px;
        font-size: 0.75rem;
      }
    }

    &.cb-icon {
      cursor: pointer;

      &::before {
        content: '';
      }

      &::after {
        content: '\e999';
        padding-left: 16px;
        font-size: 0.75rem;
      }
    }

    .cb-file-element {
      display: inline-block;
      margin-top: 0;
      text-decoration: none;

      @include roboto();
    }

    &.cb-glyph,
    &.cb-icon {
      &:hover {
        .cb-file-element {
          text-decoration: underline;
        }
      }
    }
  }

  .cb-file-name {
    word-break: break-word;
  }
}
