/*
 * CB Toast
 */

@use 'sass:map';
@use 'sass:math';
@use 'sass:color';

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/link' as *;
@use '../mixins/font' as *;
@use '../mixins/toast' as *;

.cb-toast {
  &[aria-hidden='true'] {
    display: none;
  }

  /* new toasts slide in from the top */
  @include react-transition-phase(
    'cb-toast',
    'enter',
    $enter-translate,
    $duration: $toast-transition-duration * 3,
    $easing: $toast-transition-ease,
    $before: '&'
  );

  /* previous sibling gets pushed down */
  @include react-transition-phase(
    'cb-toast',
    'enter',
    $enter-translate-sibling,
    $duration: $toast-transition-duration * 3,
    $easing: $toast-transition-ease,
    $before: '&',
    $after: '~ &'
  );

  /* leaving toasts simply fade away */
  @include react-transition-phase(
    'cb-toast',
    'exit',
    $leave-translate,
    $easing: $toast-transition-ease,
    $duration: $toast-transition-duration * 3,
    $before: '&'
  );

  /* younger siblings of leaving toasts wait a moment before moving to fill gap */
  @include react-transition-phase(
    'cb-toast',
    'exit',
    $enter-translate-sibling,
    $easing: $toast-transition-ease,
    $delay: math.div($toast-transition-duration, 2),
    $before: '&',
    $after: '~ &'
  );

  & {
    pointer-events: all;

    position: relative !important;

    display: flex;
    align-items: flex-start;

    box-sizing: content-box;
    width: 498px;
    max-width: 498px;
    margin-top: $toast-space;
    padding: 0;

    font-size: $font-size-small;
    line-height: $line-height-small;

    background: $white;
    border: 1px solid $black1;
    border-radius: 8px;
    box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  }

  @include media-breakpoint-only('xs', $grid-breakpoints) {
    width: calc(100% - 48px);
    max-width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }

  /* based on btn height */
  .cb-toast-msg,
  .cb-toast-action {
    margin: 11px 0 11px 16px;
  }

  .cb-glyph,
  .cb-icon {
    &:not(.cb-x-mark) {
      margin: 14px 0 14px 16px;
      font-size: 1rem;
      line-height: 18px;
    }

    &.cb-check,
    &.cb-check-fill {
      color: $green3;
    }

    &.cb-exclamation-circle,
    &.cb-exclamation-fill {
      color: $red1;
    }
  }

  .cb-toast-msg {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    padding-right: 16px;

    text-overflow: ellipsis;

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      -webkit-line-clamp: unset;
      text-overflow: unset;
    }
  }

  .cb-btn-close {
    min-width: 32px;
    margin: 7px 8px 7px 0;
    margin-left: auto !important;
    font-size: 14px !important;
  }

  a {
    &.cb-toast-action {
      margin-right: 16px;
      margin-left: auto !important;
      text-transform: uppercase;

      @include roboto-medium();
      @include black-link();
    }
  }

  .cb-toast-action {
    + .cb-btn-close {
      margin-left: 0 !important;
    }
  }

  &.cb-toast-alternative,
  &.cb-toast-info,
  &.cb-toast-success,
  &.cb-toast-warning {
    color: $white;

    a {
      @include white-link();
    }

    .cb-glyph,
    .cb-icon {
      &.cb-check,
      &.cb-info,
      &.cb-check-fill,
      &.cb-exclamation-circle,
      &.cb-exclamation-fill {
        color: $white !important;
      }
    }

    .cb-btn-close {
      color: $white;
      background: transparent;

      &:focus {
        color: $black1 !important;
        background-color: $gray5 !important;
      }
    }
  }

  &.cb-toast-alternative,
  &.cb-toast-info {
    background: $black1;
    border-color: $gray1;

    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          color: $white;
          background-color: color.mix($white, $black1, 20%);
          box-shadow: 0 0 0 2px $white;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &.cb-toast-success {
    background: $green3;

    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          color: $white;
          background-color: color.mix($black, $green3, 20%);
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &.cb-toast-warning {
    background: $red1;

    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          color: $white;
          background-color: color.mix($black, $red1, 20%);
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &.cb-toast-msg-no-truncate {
    .cb-toast-msg {
      overflow: unset;
      -webkit-box-orient: unset;
      -webkit-line-clamp: unset;
      text-overflow: unset;
    }
  }
}

.cb-toast-container {
  /* container will not block clicks on elements behind it */
  pointer-events: none;

  position: fixed;
  z-index: $z-index-toast;
  inset: 0 0 auto;

  overflow: hidden;
  display: flex !important;
  flex-direction: column;
  align-items: center;

  &.cb-toast-container-bottom {
    top: auto;
    bottom: 0;
    flex-direction: column-reverse;

    .cb-toast {
      /* minimal diff in react-transition styles so we can avoid calling those mixins again */
      &.cb-toast-enter:not(.cb-toast-enter-active),
      &.cb-toast-enter:not(.cb-toast-enter-active) ~ .cb-toast,
      &.cb-toast-appear:not(.cb-toast-appear-active),
      &.cb-toast-appear:not(.cb-toast-appear-active) ~ .cb-toast,
      &.cb-toast-exit-active ~ .cb-toast,
      &.cb-toast-leave-active ~ .cb-toast {
        transform: translateY($toast-space + $toast-height);
      }
    }
  }
}
