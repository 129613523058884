/*
 * CB Stepper
 */

@use 'apricot-variable' as *;
@use '../mixins/glyph' as *;
@use '../mixins/stepper' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/font' as *;

.cb-stepper {
  ul,
  ol {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      position: relative;

      width: 100%;
      margin: 0;
      padding: 0 8px;

      text-align: center;

      &::after {
        content: '';

        position: absolute;
        top: 15px;
        left: calc(50% + 22px);

        display: block;

        width: calc(100% - 44px);
        height: 2px;

        background: $gray2;
      }

      p {
        @include roboto-bold();

        margin: 0;

        & + p {
          font-size: $font-size-small;
          line-height: $line-height-small;

          @include roboto();
        }
      }

      a {
        color: $font-color-base;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: $font-color-base;
        }
      }

      .cb-glyph,
      .cb-icon {
        display: inline-flex;
        color: $white;
      }

      .cb-glyph-circular,
      .cb-icon-circular {
        &::after {
          background-color: transparent;
          border: 2px solid $gray2;
        }

        &::before {
          color: $gray2;
        }
      }

      .cb-glyph-numeric,
      .cb-icon-numeric {
        margin-bottom: 8px;
        color: $gray2;
        background-color: transparent;
        border-color: $gray2;
      }

      /* ------------------------------------ STEP STATES */
      &.cb-stepper-active {
        &::after {
          background: $gray2;
        }

        .cb-glyph,
        .cb-icon,
        .cb-glyph-numeric,
        .cb-icon-numeric {
          color: $white;
        }

        .cb-glyph-circular,
        .cb-glyph-circular {
          &::after {
            background-color: $blue5;
            border: 2px solid $blue5;
          }

          &::before {
            color: $white;
          }
        }

        .cb-glyph-numeric,
        .cb-icon-numeric {
          background-color: $blue5;
          border-color: $blue5;
        }
      }

      &.cb-stepper-skip {
        &::after {
          background: $blue5;
        }

        .cb-glyph,
        .cb-icon {
          color: $white;

          &.cb-glyph-circular,
          &.cb-icon-circular {
            &::after {
              background-color: $gray2;
              border: 2px solid $gray2;
            }

            &::before {
              color: $white;
            }
          }
        }

        .cb-glyph-numeric,
        .cb-icon-numeric {
          color: $white;
          background-color: $gray2;
          border-color: $gray2;
        }
      }

      &.cb-stepper-complete {
        &::after {
          background: $blue5;
        }

        .cb-glyph,
        .cb-icon {
          &.cb-glyph-circular,
          &.cb-icon-circular {
            &::after {
              background-color: $blue5;
              border: 2px solid $blue5;
            }

            &::before {
              color: $white;
            }
          }
        }
      }

      /* ------------------------------------ NAVIGATION */
      &.cb-stepper-disabled {
        a {
          &:hover,
          &:focus {
            cursor: not-allowed;
            text-decoration: none;
          }
        }
      }

      &:last-child {
        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &:not(.cb-stepper-simple) {
    li {
      &.cb-stepper-complete {
        .cb-glyph {
          &::before {
            content: '\e90b';
          }
        }

        .cb-icon {
          &::before {
            content: '\e953';
          }
        }
      }
    }

    &:not(.cb-stepper-horizontal-fix) {
      /* mobile + tablet */
      @include media-breakpoint-down('sm', $grid-breakpoints) {
        @include cb-v-stepper();
      }
    }
  }

  &.cb-stepper-vertical {
    @include cb-v-stepper();
  }

  /* Simple */
  &.cb-stepper-simple {
    ul,
    ol {
      li {
        &::after {
          top: 9px;
          left: calc(50% + 12px);
          width: calc(100% - 24px);
        }

        p {
          display: none;
          visibility: hidden;
        }

        .cb-glyph,
        .cb-icon {
          color: $blue5;

          &.cb-circle {
            color: $gray2;
          }
        }

        &.cb-stepper-skip,
        &.cb-stepper-active {
          .cb-glyph,
          .cb-icon {
            &.cb-circle,
            &.cb-disc {
              color: $blue5;
            }
          }
        }
      }
    }
  }

  /* Illustrations/Content Glyph */
  &.cb-stepper-content-glyph,
  &.cb-stepper-ilus {
    ul,
    ol {
      li {
        &::after {
          content: '';

          position: absolute;
          top: 32px;
          left: calc(50% + 40px);

          display: block;

          width: calc(100% - 80px);
          height: 2px;

          background: $gray2;
        }

        &:last-child {
          &::after {
            width: 0;
            height: 0;
          }
        }
      }
    }

    /* ------------------------------------ COLOR */
    @include glyph-multi-content-color-stepper(blue5, $white, $black1, $blue5);
    @include glyph-multi-content-color-stepper(blue3, $white, $black1, $blue3);
    @include glyph-multi-content-color-stepper(blue4, $white, $black1, $blue4);
    @include glyph-multi-content-color-stepper(yellow1, $black1, $black1, $yellow1);
    @include glyph-multi-content-color-stepper(purple1, $white, $black1, $purple1);
    @include glyph-multi-content-color-stepper(purple2, $white, $black1, $purple2);
    @include glyph-multi-content-color-stepper(green1, $white, $black1, $green1);
    @include glyph-multi-content-color-stepper(green2, $white, $black1, $green2);
  }
}
