/*
 * CB Band
 */

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/font' as *;
@use '../mixins/typography' as *;

.cb-band {
  /* ------------------------------------ HERO */

  &.cb-band-hero {
    .row {
      &:first-of-type {
        align-items: center;
        min-height: 576px;

        @include media-breakpoint-down('md', $grid-breakpoints) {
          min-height: 552px;
        }

        @include media-breakpoint-only('xs', $grid-breakpoints) {
          min-height: auto;
        }
      }

      &.cb-band-hero-height-auto {
        min-height: auto !important;
      }
    }

    &.cb-band-hero-bg,
    .cb-band-hero-bg {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &.cb-band-hero-xs-stack {
      .cb-band-hero-content {
        padding: 0;
      }
    }

    .cb-band-hero-img-xs {
      display: none;
    }

    .cb-band-hero-content {
      padding: 72px 0;

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        padding: 48px 0;
      }

      .cb-text-overline {
        display: inline-flex;

        margin-bottom: 24px;
        padding-top: 8px;

        font-size: 1rem;
        line-height: 1.5em;

        border-style: solid;
        border-width: 0;
        border-top-width: 1px;

        @include media-breakpoint-only('md', $grid-breakpoints) {
          margin-bottom: 16px;
        }

        @include media-breakpoint-only('sm', $grid-breakpoints) {
          margin-bottom: 8px;
        }
      }

      .cb-band-hero-title {
        font-weight: 400;
      }

      .cb-band-hero-desc {
        @include cb-paragraph2-res();

        & {
          font-weight: 300;
        }
      }

      .cb-band-hero-title {
        + .cb-band-hero-desc {
          margin-top: 24px;
        }
      }

      .cb-text-overline {
        + .cb-band-hero-desc {
          margin-top: 0;
        }
      }

      .cb-btn {
        font-size: 1rem;
        line-height: 1.5em;

        @each $breakpoint, $value in $grid-breakpoints {
          @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint} {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }

          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint}-up {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }

          @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint}-down {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }
        }
      }

      .cb-btn-row {
        margin-top: 48px;
      }
    }

    /* ------------------------------------ Band specific Mobile treatment */

    /* mobile */
    @include media-breakpoint-only('xs', $grid-breakpoints) {
      .cb-band-hero-content {
        text-align: left;

        .cb-btn-row {
          margin-top: 32px;
        }

        .cb-btn {
          font-size: $font-size-small;
          line-height: $line-height-small;
        }
      }

      .cb-band-hero-img-xs {
        display: flex;
        flex-basis: auto;
        align-items: center;
        justify-content: center;
      }

      &.cb-band-hero-xs-stack {
        .row {
          &:first-of-type {
            margin: 0 -24px;
          }
        }

        div[class^='col-']:not(.cb-band-hero-content) {
          padding-right: 0;
          padding-left: 0;
        }

        .cb-band-hero-content {
          padding: 48px 24px;
        }

        &.cb-band-hero-bg,
        .cb-band-hero-bg {
          background-image: none !important;
        }
      }
    }
  }

  /* ------------------------------------ PANEL */
  &.cb-band-panel {
    padding: 72px 0;

    .cb-band-panel-content {
      .cb-band-panel-header {
        .cb-band-panel-title {
          font-weight: 400;

          &:not(h2) {
            /* 24 */
            font-size: 1.5rem;

            /* 32 */
            line-height: 1.3333em;
            letter-spacing: -0.5px;

            /* sm, md */
            @include media-breakpoint-up('sm', $grid-breakpoints) {
              /* 32 */
              font-size: 2rem;

              /* 40 */
              line-height: 1.25em;
            }

            /* lg, xl, 2xl, 3xl */
            @include media-breakpoint-up('lg', $grid-breakpoints) {
              /* 40 */
              font-size: 2.5rem;

              /* 48 */
              line-height: 1.2em;
            }
          }
        }

        .cb-text-overline {
          display: inline-flex;

          margin-bottom: 24px;
          padding-top: 8px;

          font-size: 1rem;
          line-height: 1.5em;

          border-top: 1px solid $black1;
        }
      }

      .cb-band-panel-desc {
        margin-top: 8px;

        /* xs */
        font-size: 1rem;
        line-height: 1.5em;

        /* lg and up */
        @include media-breakpoint-up('lg', $grid-breakpoints) {
          /* 21 */
          font-size: 1.3125rem;

          /* 32 */
          line-height: 1.5238em;
        }
      }

      .cb-band-panel-line {
        margin-top: 16px;
        margin-bottom: 16px;
        border-top: 1px solid $black1;
      }

      .cb-band-panel-header {
        + .cb-band-panel-components {
          margin-top: 48px;
        }
      }

      .cb-band-panel-footer {
        margin-top: 48px;
      }
    }

    @include media-breakpoint-between('md', 'lg', $grid-breakpoints) {
      .cb-band-panel-50-2 {
        padding-left: 24px;
      }

      .cb-band-panel-50-1 {
        padding-right: 24px;

        &.order-md-last {
          padding-right: 12px;
          padding-left: 24px;

          + .cb-band-panel-50-2 {
            padding-right: 24px;
            padding-left: 12px;
          }
        }
      }
    }

    @include media-breakpoint-up('xl', $grid-breakpoints) {
      .cb-band-panel-50-2 {
        padding-left: 36px;
      }

      .cb-band-panel-50-1 {
        padding-right: 36px;

        &.order-md-last {
          padding-right: 12px;
          padding-left: 36px;

          + .cb-band-panel-50-2 {
            padding-right: 36px;
            padding-left: 12px;
          }
        }
      }
    }

    &.cb-band-panel-image {
      display: flex;
      align-items: center;

      min-height: 580px;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &.cb-band-panel-image-dark {
        position: relative;

        &::before {
          content: ' ';

          position: absolute;
          top: 0;
          left: 0;

          display: block;

          width: 100%;
          height: 100%;

          background-color: rgb(0 0 0 / 70%);
        }
      }
    }

    &.cb-band-panel-radius-24 {
      position: relative;
      margin-top: -24px;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
  }
}

/* ------------------------------------ HERO OPTIONS */
.cb-band {
  &.cb-band-hero {
    /* ------------------------------------ ARTICLE */
    &.cb-band-hero-article {
      .cb-band-hero-content {
        .cb-band-hero-title {
          + .cb-band-hero-desc {
            margin-top: 32px;
          }

          @include media-breakpoint-only('xs', $grid-breakpoints) {
            font-size: 40px;
            line-height: 48px;

            + .cb-band-hero-desc {
              margin-top: 24px;
            }
          }
        }

        .cb-text-overline {
          margin-bottom: 40px;

          @include media-breakpoint-only('2xl', $grid-breakpoints) {
            margin-bottom: 32px;
          }

          @include media-breakpoint-only('xs', $grid-breakpoints) {
            margin-bottom: 24px;
          }
        }
      }
    }

    /* ------------------------------------ BACKGROUND */
    &.cb-band-hero-bg {
      .cb-btn-row {
        margin-top: 24px;
      }
    }

    /* ------------------------------------ FOCUS */
    &.cb-band-hero-focus {
      .row {
        &:first-of-type {
          min-height: auto !important;
        }
      }

      @include media-breakpoint-down('lg', $grid-breakpoints) {
        .container {
          max-width: 100%;
        }
      }

      .cb-band-hero-content {
        padding: 0 12px !important;
      }

      .cb-band-hero-title {
        font-size: 64px;
        line-height: 72px;
        letter-spacing: -1px;
      }

      .cb-btn-row {
        margin-top: 24px;
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        .cb-band-hero-content {
          padding: 24px 24px 48px !important;

          .cb-text-overline:first-child {
            margin-top: 24px;
          }
        }

        .cb-band-hero-title {
          font-size: 40px !important;
          line-height: 48px !important;

          + .cb-band-hero-desc {
            margin-top: 24px;
          }
        }
      }

      @include media-breakpoint-only('sm', $grid-breakpoints) {
        h1 {
          font-size: 36px !important;
          line-height: 42px !important;
          letter-spacing: -1px !important;

          + .cb-band-hero-desc {
            margin-top: 16px;
          }
        }

        .cb-band-hero-desc {
          font-size: 21px !important;
          line-height: 24px;
        }
      }

      @include media-breakpoint-only('md', $grid-breakpoints) {
        .cb-band-hero-title {
          font-size: 48px;
          line-height: 54px;
          letter-spacing: -1px;

          + .cb-band-hero-desc {
            margin-top: 16px;
          }
        }

        .cb-band-hero-desc {
          font-size: 24px !important;
          line-height: 32px;
        }

        img {
          max-height: 480px;
        }
      }
    }

    /* ------------------------------------ CARD */
    &.cb-band-hero-card {
      position: relative;

      @include media-breakpoint-up('lg', $grid-breakpoints) {
        .cb-date-time-tags {
          p {
            font-size: 1rem;
            line-height: 1.5em;
          }
        }
      }

      @include media-breakpoint-up('md', $grid-breakpoints) {
        .cb-band-hero-card-polygon {
          display: none;
        }
      }

      @include media-breakpoint-down('sm', $grid-breakpoints) {
        .cb-band-hero-card-polygon {
          position: absolute;
          bottom: 0;
          left: 0;

          display: block;
          shape-outside: polygon(0 300px, 100% 300px, 99.87% 0, 0 162px);

          width: 100%;
          height: 300px;

          clip-path: polygon(0 300px, 100% 300px, 99.87% 0, 0 162px);
        }

        .cb-card {
          margin-bottom: 72px;
        }

        .cb-band-hero-content {
          padding-bottom: 64px;
        }
      }

      /* ------------------------------------ IDENTITY CARD */
      .cb-card {
        &.cb-identity-card {
          width: auto;
          min-width: auto;

          @include media-breakpoint-up('md', $grid-breakpoints) {
            padding: 32px;
          }

          @include media-breakpoint-up('xl', $grid-breakpoints) {
            padding: 48px;
          }

          @media (max-width: 518px) {
            p {
              width: auto;
            }
          }
        }
      }
    }

    /* ------------------------------------ SVG BACKGROUND PATTERN */
    &.cb-band-svg-pattern {
      /* palette */
      position: relative;

      svg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
      }

      @each $key, $value in $palette-colors {
        &.cb-palette-#{$key} {
          background-color: $value;
        }
      }
    }

    /* ------------------------------------ ILLUSTRATION */

    &.cb-band-hero-illustration {
      .row {
        &:first-of-type {
          align-items: unset;
          min-height: 416px !important;
        }
      }

      .cb-band-hero-content {
        padding: 64px 0 !important;

        .cb-band-hero-title {
          font-size: 3rem;
          font-weight: 900;

          @include media-breakpoint-only('xs', $grid-breakpoints) {
            font-size: 2rem;
          }

          + .cb-band-hero-desc {
            margin-top: 8px;
          }
        }

        .cb-band-hero-desc {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5em;
        }

        @media (max-width: 375px) {
          padding: 48px 0 !important;
        }

        @include media-breakpoint-only('xs', $grid-breakpoints) {
          text-align: center;
        }
      }

      .cb-btn-row {
        margin-top: 8px !important;
      }
    }

    /* ------------------------------------ CONDENSED */

    &.cb-band-hero-condensed {
      .row {
        &:first-of-type {
          min-height: 288px;

          @include media-breakpoint-down('md', $grid-breakpoints) {
            min-height: 288px;
          }
        }
      }

      .cb-band-hero-content {
        padding: 48px 0;

        .cb-text-overline {
          display: none;
        }

        .cb-band-hero-desc {
          font-size: 1.5rem;
          line-height: 1.33em;
        }
      }
    }

    /* ------------------------------------ PALETTE */
    @each $key, $value in $palette-colors {
      &.cb-palette-#{$key} {
        background-color: $value;
      }
    }
  }
}

.cb-svg-code {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

use.cb-svg-use {
  stroke: $black1;
}

@each $key, $value in $palette-svg-colors {
  .cb-palette-#{$key} {
    use.cb-svg-use {
      stroke: $value;
    }
  }
}

.cb-svg-pattern-code {
  width: 0;
  height: 0;
}

.cb-svg-pattern {
  path,
  rect,
  line {
    stroke: inherit;
  }
}

/* ------------------------------------ BOUNCING BALL ANIMATION EFFECT */
@keyframes grow {
  from {
    bottom: 0;
    width: 200px;
    height: 50px;
    margin-left: -100px;
  }

  to {
    bottom: 20px;
    width: 15px;
    height: 10px;
    margin-left: 0;
  }
}

@keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 100%;
  }
}
