/*
 * CB Slider
 */

@use 'apricot-variable' as *;
@use '../mixins/breakpoint' as *;

@mixin horizontal-slider() {
  flex-direction: column;
  align-items: flex-start !important;

  .cb-input-container {
    display: flex;

    .cb-input-slider {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;

      label {
        margin-top: 8px;
        margin-left: 0;
      }

      + .cb-input-slider {
        margin-left: 8px;
      }
    }
  }
}

.cb-slider,
.cb-slider * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.cb-slider {
  position: relative;

  width: 100%;
  height: 4px;

  background-color: $gray4;
  border-radius: 2px;

  .cb-slider-base,
  .cb-slider-connects {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .cb-slider-connects {
    z-index: 0;
    overflow: hidden;
    border-radius: 2px;

    .cb-slider-connect,
    .cb-slider-origin {
      will-change: transform;

      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      transform-origin: 0 0;
      transform-style: flat;

      width: 100%;
      height: 100%;
    }

    .cb-slider-origin {
      right: auto;
      left: 0;
      height: 0;
    }

    .cb-slider-connect {
      background: $blue5;
    }
  }
}

.cb-slider-handle {
  cursor: default;

  position: absolute;
  top: -8px;
  right: -5px;

  box-sizing: content-box;
  width: 8px;
  height: 8px;

  backface-visibility: hidden;
  background: $blue5;
  border: 2px solid $blue5;
  border-radius: 50%;

  transition: box-shadow 0.3s ease-out;

  &:hover {
    cursor: pointer;
    background: $white;
  }

  &.cb-slider-active,
  &:focus {
    cursor: pointer;

    top: -14px;
    right: -10px;

    width: 18px;
    height: 18px;

    background: $black1;
    border: 2px solid $white;
    outline: none;
    box-shadow: 0 0 0 1px $blue5;

    &::after {
      content: '';

      position: absolute;
      top: 3px;
      right: 3px;

      display: block;

      box-sizing: content-box;
      width: 8px!important;
      height: 8px!important;

      background: $white;
      border: 2px solid $white;
      border-radius: 50%;
    }
  }

  &.cb-slider-active {
    background: $blue5;
    border: 2px solid $white;
    box-shadow: 0 0 0 1px $blue5;

    &::after {
      display: none;
    }
  }
}

.cb-slider-touch-area {
  width: 24px;
  height: 24px;
  margin-top: -8px;
  margin-left: -8px;
}

.cb-slider-state-tap .cb-slider-connect,
.cb-slider-state-tap .cb-slider-origin {
  transition: transform 0.3s;
}

/* Base;
 *
 */
.cb-slider-pips,
.cb-slider-pips * {
  box-sizing: border-box;
}

.cb-slider-pips {
  position: absolute;
  color: $gray2;
}

/* Values;
  *
  */
.cb-slider-value {
  position: absolute;

  line-height: 32px;
  color: $gray1;
  text-align: center;
  white-space: nowrap;
}

/* Markings;
  *
  */
.cb-slider-marker {
  position: absolute;
  background: $gray2;
}

.cb-slider-marker-large {
  background: $gray1;
}

.cb-slider-pips {
  top: 0;
  left: 0;
  width: 100%;
  height: 24px;
}

.cb-slider-value {
  transform: translate(-50%, 50%);
}

.cb-slider-marker.cb-slider-marker {
  width: 1px;
  height: 4px;
  margin-left: -1px;
}

.cb-slider-marker.cb-slider-marker-large {
  height: 12px;
}

/* ------------------------------------ TOOLTIP */
.cb-slider-tooltip {
  position: absolute;
  z-index: $z-index-tooltip;
  top: -42px;
  left: 50%;
  transform: translate(-50%, 0);

  display: none;

  padding: 0 6px;

  font-size: $tooltip-font-size;
  color: $tooltip-color;
  text-align: center;
  white-space: nowrap;

  background: $tooltip-bg;
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
}

.cb-slider-handle {
  &:not([aria-disabled]) {
    &.cb-slider-active,
    &:focus {
      .cb-slider-tooltip {
        top: -36px;
        display: block;
      }
    }
  }
}

.cb-slider-sticky-tooltip {
  .cb-slider-tooltip {
    display: block;
  }
}

/* With inputs/span */
.cb-slider-container {
  display: flex;
  width: 100%;

  &.cb-slider-single {
    align-items: center;
  }

  &.cb-slider-range {
    align-items: flex-end;
  }

  .cb-input-container {
    width: 20%;

    .cb-input-slider {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      label {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }

      input {
        min-width: 64px;
        padding: 4px 11px 4px 12px;

        font-size: $font-size-sm;
        line-height: 1.5em;

        background-color: $blue5-tint2;
        border: 1px solid $blue5-tint2;
        border-radius: $border-radius;

        &:disabled,
        &:read-only {
          cursor: not-allowed !important;

          color: $black1;

          -webkit-opacity: 1;
          opacity: 1; /* Override iOS opacity change affecting text & background color */
          background: $gray5 !important;
          border: 1px solid $gray4 !important;

          -webkit-text-fill-color: $gray1; /* Override iOS / Android font color change */
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &:read-only:not(:disabled) {
          cursor: default !important;
          border-color: $gray5 !important;
        }
      }

      + .cb-input-slider {
        margin-top: 8px;
      }
    }
  }

  &.cb-slider-stacked {
    @include horizontal-slider();
  }
}

@include media-breakpoint-only('xs', $grid-breakpoints) {
  .cb-slider-container {
    @include horizontal-slider();
  }
}

.cb-slider-min,
.cb-slider-max {
  display: inline-flex;
  padding: 7px 15px;
  font-size: $font-size-sm;
  line-height: 1.5em;
}

/* Disabled state; */
[disabled],
[aria-disabled] {
  &.cb-slider,
  .cb-slider,
  &.cb-slider-handle,
  .cb-slider-handle {
    cursor: not-allowed;
  }

  &.cb-slider,
  .cb-slider {
    .cb-slider-connect {
      background: $gray5;
    }

    .cb-slider-handle {
      &,
      &:hover,
      &:focus,
      &.cb-slider-active {
        top: -8px !important;
        right: -5px !important;

        width: 8px !important;
        height: 8px !important;

        background: $gray5 !important;
        border-color: $gray4 !important;
        box-shadow: none !important;

        &::after {
          display: none;
        }
      }

      &.cb-slider-active,
      &:focus {
        .cb-slider-tooltip {
          top: -42px;
        }
      }
    }
  }

  &.cb-slider-handle,
  .cb-slider-handle {
    &,
    &:hover,
    &:focus,
    &.cb-slider-active {
      top: -8px !important;
      right: -5px !important;

      width: 8px !important;
      height: 8px !important;

      background: $gray5 !important;
      border-color: $gray4 !important;
      box-shadow: none !important;

      &::after {
        display: none;
      }
    }
  }

  .cb-slider-tooltip {
    color: $black1;
    background-color: $gray4;
    border: 1px solid $tooltip-border-color;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  }
}

.cb-slider {
  &.cb-slider-black {
    .cb-slider-connects {
      .cb-slider-connect {
        background: $black1;
      }
    }

    .cb-slider-handle {
      background: $black1;
      border: 2px solid $black1;

      &:hover {
        cursor: pointer;
        background: $white;
      }

      &.cb-slider-active,
      &:focus {
        background: $blue5;
        border-color: $white;
        box-shadow: 0 0 0 1px $black1;
      }

      &.cb-slider-active {
        background: $black1;
        box-shadow: 0 0 0 1px $blue5;
      }
    }
  }
}
