/*
 * CB Multi Select
 */

@use 'apricot-variable' as *;

.cb-multiselect-checkbox-container {
  .cb-input-helper {
    width: calc(100% - 48px);
  }

  .cb-multiselect-checkbox {
    position: relative;

    .cb-multiselect-checkbox-toggle {
      position: relative;

      display: block;

      width: 100%;
      min-height: 48px;
      padding: 11px 15px;

      color: $black1;
      text-decoration: none;

      border: 1px solid $gray4;
      border-radius: 8px;

      .cb-label {
        transition-timing-function: linear;
        transition-duration: 0.1s;
        transition-property: top, font-size;
      }

      .cb-multiselect-checkbox-toggle-icon {
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: $font-size-small;

        i:not(.cb-x-mark) {
          font-size: 0.6875rem;
          font-weight: 700;
        }
      }

      &:focus {
        border: 1px solid $blue5;
      }
    }

    .cb-multiselect-checkbox-dropdown {
      padding-top: 0;
      padding-bottom: 16px;

      background: $white;
      border-top: 1px solid $gray4;
      border-radius: 0 0 8px 8px;

      .cb-checkbox {
        margin-left: 0;
        padding: 8px 16px;
        background: transparent;

        &:not(.cb-label-left) {
          flex-grow: 1;

          label {
            flex-grow: 1;
            justify-content: flex-start;
          }
        }

        &:hover {
          cursor: pointer;
          background: $gray5;
          border-radius: 0;
          transition: all 0.2 ease-in-out;
        }
      }

      .cb-multiselect-filter {
        margin: 0;
        padding: 8px 16px;
        border-bottom: 1px solid $gray4;
      }

      .cb-multiselect-checkbox-list {
        overflow-y: scroll;
        max-height: 250px;

        .cb-multiselect-checkbox-all {
          margin-top: 8px;
          padding-bottom: 8px;

          .cb-checkbox {
            margin-bottom: 0;
          }

          + .cb-checkbox {
            margin-top: 8px;
          }
        }

        .cb-no-results {
          margin: 24px 16px 8px;
          margin-top: 0;
          font-size: $font-size-small;
          line-height: 1.71em;
        }
      }
    }

    &.open {
      border: 1px solid $blue5;
      border-radius: 8px;
      box-shadow: 0 0 12px rgba($color: $black, $alpha: 20%);

      &.cb-validation-state {
        width: calc(100% - 48px);

        .cb-validation-icon {
          display: none;
        }
      }

      .cb-multiselect-checkbox-toggle {
        border-color: $white;
      }

      .cb-multiselect-checkbox-toggle-icon {
        &:hover,
        &:focus {
          border-bottom: 1px solid $black1;
        }
      }

      &.in-modal {
        border: 1px solid $blue5;
        box-shadow: none;
      }
    }

    &.open,
    &.cb-multiselect-checkbox-selected {
      .cb-multiselect-checkbox-toggle {
        > .cb-label {
          position: absolute;
          z-index: 2;
          top: -10px;
          left: 14px;

          padding: 0 2px;

          font-size: $font-size-x-small;
          font-weight: 500;
          line-height: 1.7143em;
          color: $gray1;

          background-color: $white;
        }
      }
    }

    &.open {
      .cb-multiselect-checkbox-toggle {
        > .cb-label {
          color: $blue5;
        }
      }
    }

    &.cb-disabled {
      .cb-multiselect-checkbox-toggle {
        cursor: not-allowed !important;
        color: $cb-disabled-font-color !important;

        .cb-label {
          color: $cb-disabled-font-color !important;
        }

        .cb-icon {
          color: $cb-disabled-font-color !important;
        }
      }

      &:hover {
        cursor: not-allowed !important;
      }
    }
  }

  .cb-tag-set {
    margin-top: 0;
    margin-left: -8px;

    .cb-btn {
      margin-top: 8px;
      margin-left: 8px !important;
    }
  }

  .cb-divider {
    border-bottom: 1px solid $gray4;

    .cb-group-title {
      margin-top: 24px;
      margin-right: 16px;
      margin-left: 16px;

      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5em;

      + .cb-group-desc {
        margin: 0 16px 8px;
        font-size: $font-size-small;
        line-height: 1.71em;
      }

      + .cb-checkbox {
        margin-top: 8px;
      }
    }

    &.cb-multiselect-grouped-checkbox {
      border-bottom: 0 !important;
    }
  }

  .cb-multiselect-checkbox-header {
    font-size: $font-size-x-small;
    font-weight: 700;
    line-height: 1.3333em;
    color: $gray2;
  }

  .cb-multiselect-checkbox-list {
    fieldset {
      .cb-divider {
        .cb-checkbox {
          + .cb-checkbox {
            margin-top: 8px;
          }

          &:last-of-type {
            margin-bottom: 24px;
          }
        }
      }

      &:last-of-type {
        .cb-divider {
          border-bottom: 0;

          .cb-checkbox {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .cb-multiselect-checkbox-all-text {
    margin: 8px 16px;
    font-size: $font-size-small;
    line-height: 1.71em;
  }

  .cb-multiselect-grouped-checkbox {
    .cb-group-desc {
      margin-left: 48px;
    }

    .cb-checkbox:not(.cb-checkbox-nested) {
      padding-left: 48px !important;
    }
  }

  &.cb-condensed {
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 7px 15px;

    p {
      font-size: $font-size-small;
      line-height: $line-height-small;
    }

    .cb-multiselect-checkbox {
      .cb-multiselect-checkbox-toggle {
        height: 2.5rem;
        min-height: 2.5rem;
        padding: 7px 15px !important;

        .cb-multiselect-checkbox-toggle-icon {
          top: 8px;

          .cb-icon-16 {
            font-size: $font-size-small;
          }
        }
      }
    }

    .cb-divider {
      .cb-group-title {
        margin-top: 12px;

        + {
          .cb-checkbox {
            margin-top: 4px;
          }
        }
      }
    }

    .cb-multiselect-grouped-checkbox {
      .cb-group-desc {
        margin-left: 36px;
      }

      .cb-checkbox:not(.cb-checkbox-nested) {
        padding-left: 36px !important;
      }
    }
  }
}
